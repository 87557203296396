export const environment = {
  production: false,
  envName: 'development',
  urls: {
    moderationApi: 'https://moderation-api.dev.az.ebbo.com/'
  },
  oidc: {
    server: 'https://login.microsoftonline.com/050ea3de-6ab5-4bb6-ae57-0100b14df04d/v2.0',
    redirectUrl: 'https://moderation-web.dev.az.ebbo.com/',
    clientId: '423270a5-bf9f-4210-926d-489ff77793df',
    scopes: 'openid profile',
    logoutRedirectUrl: 'https://moderation-web.dev.az.ebbo.com/',
    issuerUri: 'https://login.microsoftonline.com/050ea3de-6ab5-4bb6-ae57-0100b14df04d/v2.0'
  },
  retryAttempts: 2
};
